@import "basic-styles";

@mixin alignment($display ,$justify, $align, $gap) {
  display: grid;
  align-self: $align;
  justify-self: $justify;
  grid-gap: $gap;
}

/* extension panels */
.mat-expansion-panel {
  background-color: $bg2-color;
  border-radius: $panel-border-radius;
  margin-bottom: 20px;
  padding: 0;
  box-shadow: 0 3px 12px rgb(0 0 0 / 4%);
}


.mat-expansion-panel:not([class*=mat-elevation-z]) {
  box-shadow: 0 3px 12px rgb(0 0 0 / 4%);
}

// TODO: Need to remove, but check all the dialogues. Breaks correct material styles
::ng-deep .mat-dialog-container {
  position: fixed;
  height: 100vh;
  top: 0;
  z-index: 10;
  background-color: $caption-text-color;
}

.mat-expansion-panel-header {
  padding: 12px 24px;
}

/* dialog windows angular mat component */
mat-dialog-container {
  left: 0;
}

/* avatars and imgs */
.avatar-img {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  border: 1px solid $borders-color;
  object-fit: cover;
}

/* checkboxes */
mat-checkbox {
  align-self: center;
  justify-self: end;
}

//.mat-checkbox-inner-container{
//  background: #333;
//}

/* common button style */
button,
.button {
  border-radius: 12px;
  padding: 0 25px;
  line-height: 45px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  height: 45px;
  color: $buttons-text-color;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    opacity: 0.6;
  }
}

.primary-button {
  background-color: $accent-color3;

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.primary-button2 {
  background-color: $primary-color;

  &:disabled {
    pointer-events: none;
    opacity: 0.6;
  }
}

.in-active-primary-button {
  background-color: $borders-color;
}

.secondary-button {
  background-color: unset;
  color: $icon-color;
  border: 1px solid $borders-color;
  cursor: pointer;

  &:hover {
    opacity: 1;
    background-color: $bg1-color;
  }
}

.secondary-button-icons {
  background-color: unset;
  color: $icon-color;
  border: 1px solid $borders-color;
  cursor: pointer;
  display: grid;
  padding: 0;
  width: 45px;
  height: 45px;
  line-height: 45px;

  span {
    align-self: center;
    text-align: center;
    justify-self: center;
  }

  &:hover {
    opacity: 1;
    background-color: $bg1-color;
  }
}

.secondary-button-icons {
  background-color: unset;
  width: 45px;
  height: 45px;
  display: grid;
  padding: 0;
  color: $icon-color;
  border: 1px solid $borders-color;
  cursor: pointer;

  .material-icons {
    align-self: center;
    cursor: pointer;
  }

  &:hover {
    opacity: 1;
    background-color: $bg1-color;
  }
}

.buttons-container {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 20px;
}


/* inputs */
input:not(.no-global-input-styles), select:not(.no-global-input-styles), textarea:not(.no-global-input-styles) {
  height: 45px;
  border-radius: 12px;
  background-color: $bg2-color;
  border: 1px solid $borders-color;
  padding: 0 15px;
  outline: none;
  color: $caption-text-color;
  font-weight: bold;

  &:hover {
    transition: 0.3s;
    border: 1px solid $caption-text-color;
  }
}

select {
  cursor: pointer;
}

.inputs-container {
  padding: 5px 0;
  display: grid;
  grid-gap: 5px 20px;
}

::ng-deep .mat-form-field-infix {
  width: fit-content;
}

/* page tool container */
.table-navigation-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  .right-side {
    justify-self: end;
  }

  .left-side {
    justify-self: start;
    grid-gap: 20px;
    display: flex;
    flex-wrap: nowrap;
  }
}

.table-img {
  object-fit: cover;
  height: 32px;
  width: 32px;
}

mat-paginator {
  border-radius: 0 0 15px 15px;
}

::ng-deep .mat-checkbox-input {
  display: none;

}

/* panels */
.panel {
  background-color: $bg2-color;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 15px;
  padding: 20px;
}

/* The header for panels that include an edit or delete options on right side */
.panel-header {
  display: grid;
  grid-template-columns: auto  min-content;
  grid-gap: 20px;

  h6 {
    align-self: start;
  }

  button {
    align-self: center;
    justify-self: end;
  }
}

.inActivePanel {
  background-color: $bg3-color;
}

.panel-active {
  cursor: pointer;

  &:hover {
    background-color: $bg3-color;
  }
}

.confirmed-time {
  padding-bottom: 20px;
  display: flex;
  align-items: end;
  gap: 5px;
}
