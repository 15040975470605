/* main colors */
$bg1-color: #F8F8FA; /* main body background */
$bg2-color: rgba(255, 255, 255, 1); /* background for blocks, panels and cards */
$bg3-color: rgba(255, 255, 255, 0.6); /* Active panels */
$placeholder-color: rgba(100, 100, 114, 0.45); /* placeholder */
$icon-color: #646472; /* icons background */
$secondary-color: #646472; /* For secondary texts items, etc. */
$icons-active-color: #ffffff; /* active icons color */
$accent-color: #67E16C; /* buttons and accent items */
$accent-color2: #FBA17A; /* errors */
$accent-color3: #2AB37B; /* accept buttons graphics etc. */
$accent-color4: #313976; /* graphics, text etc. */
$primary-color: #292639; /* text & highlights */
$primary-color2: #CA489A; /* service, texts & buttons */
$borders-color: #E9E9F1; /* borders and inactive buttons */
$main-text-color: #292639; /* main text color */
$buttons-text-color: #ffffff; /* main text color */
$caption-text-color: rgba(100, 100, 114, 0.75); /* secondary text & captions color */

/* Shadows */
$panels-shadow: 0 5px 20px rgba(100, 100, 114, 0.15); /* shadows on cards and another elements */

/* Panels */
$panel-border-radius: 15px;
$border-default: 1px solid $borders-color;

/* Panels grid gaps */
$panel-grid-gap: 3vh 1.5vw;

$default-offset: 1rem;
$default-offset-half: 0.5rem;
$default-offset-quoter: 0.25rem;
$default-offset-double: 2rem;

$cp-height: 70px;
$cp-offset: $cp-height + 80px;
$legend-height: 1.2rem;

$mat-header-row-height: 56px;
/* text-sizes */
* {
  text-shadow: 1px 1px 1px rgba(0,0,0,0.004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
}


h1 {
  font-size: 96px;
  font-weight: bold;
  color: $main-text-color;
}

h2 {
  font-size: 64px;
  font-weight: bold;
  color: $main-text-color;
}

h3 {
  font-size: 48px;
  font-weight: bold;
  color: $main-text-color;
}

h4 {
  font-size: 34px;
  font-weight: bold;
  color: $main-text-color;
}

/* main text & titles */
h5 {
  font-size: 24px;
  font-weight: bold;
  color: $main-text-color;
  margin: 0;
}

h6 {
  font-size: 20px;
  font-weight: bold;
  color: $main-text-color;
  margin: 0;
}


/* secondary text */
.caption-text {
  font-size: 14px;
  font-weight: 400;
  color: $caption-text-color;
}

@mixin overline-text {
  font-size: 0.8125rem;
  font-weight: 400;
  color: $caption-text-color;
  text-transform: uppercase;
}

.overline-text {
  font-size: 13px;
  font-weight: 400;
  color: $caption-text-color;
  text-transform: uppercase;
}

.link-text {
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
  color: $main-text-color;
}

.delete-text {
  font-size: 16px;
  font-weight: normal;
  text-decoration: underline;
  color: darkred;
  cursor: pointer;
}

.body-regular-text {
  font-size: 16px;
  font-weight: 500;
  color: $main-text-color;
}

.error-text {
  font-size: 14px;
  font-weight: normal;
  color: #f44336;
}

.body-highlighted-text {
  font-size: 17px;
  font-weight: 600;
  color: $main-text-color;
}

.thin-text {
  font-weight: 400;
}

/* icons */
.material-icons {
  color: $icon-color;

  &:hover {
    color: $caption-text-color;
  }
}

.dashboard-panel-title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
  color: $main-text-color;
  margin: 0;
}

.dashboard-block-title {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 24px;
  color: $main-text-color;
  margin: 0;
}

.dashboard-content-text {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 18px;
  color: $main-text-color;
  margin: 0;
  align-self: center;
}

.participant-status-options {
  &.mat-select-panel {
    max-height: 512px;
  }
}

.text-decoration-none {
  text-decoration: none;
}

.cursor-pointer {
  cursor: pointer;
}